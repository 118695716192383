<!-- Simplified version of https://github.com/nono1526/vue-material-year-calendar -->

<template>
  <div class="year-calendar-container">
    <div class="months-container" :class="{'hide-weekend': hideWeekend}">
      <month-calendar
        class="month-calendar-container"
        v-for="n in 12"
        :key="`month-${n}`"
        :year="year"
        :month="n - 1"
        :events="eventsByMonth[n]"
      />
      <div class="month-calendar-container"></div>
      <div class="month-calendar-container"></div>
      <div class="month-calendar-container"></div>
      <div class="month-calendar-container"></div>
      <div class="month-calendar-container"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MonthCalendar from './MonthCalendar';

export default {
  components: {
    MonthCalendar
  },
  props: {
    events: {
      type: Array,
      default: () => []
    },
    year: {
      type: Number,
      default: moment().year()
    },
    hideWeekend: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    eventsByMonth() {
      let eventsByMonth = {}
      for(let event of this.events) {
        let momentDate = moment(event.date);
        if(momentDate.year() !== this.year) {
          continue;
        }
        let month = momentDate.month() + 1;
        if(!eventsByMonth[month]) {
          eventsByMonth[month] = []
        }
        eventsByMonth[month].push(event)
      }
      return eventsByMonth;
    }
  }
}
</script>

<style lang="scss" scoped>
.year-calendar-container {
  min-width: 0;
  position: relative;
  text-decoration: none;
  margin: -8px;

  .months-container {
    flex-wrap: wrap;
    display: flex;
  }

  .month-calendar-container {
    padding: 8px;
    flex: 16.66%;

    @media screen and (max-width: 1300px) {
      flex: 25%;
    }

    @media screen and (max-width: 992px) {
      flex: 33.3%;
    }

    @media screen and (max-width: 768px) {
      flex: 50%;
    }

    @media screen and (max-width: 450px) {
      flex: 100%;
    }
  }
}
</style>

<style lang="scss">
.months-container.hide-weekend {
  .year-calendar-day:nth-of-type(7n), .year-calendar-day:nth-of-type(7n-1) {
    display: none;
  }
  .year-calendar-day {
    flex: 19%;
  }
}
</style>
