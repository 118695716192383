<template>
  <div>
    <b-checkbox v-model="am" @input="emitInput()" :disabled="!pm || forceAm">AM</b-checkbox>
    <b-checkbox v-model="pm" @input="emitInput()" :disabled="!am || forcePm">PM</b-checkbox>
  </div>
</template>

<script>
import dayParts from "@/utils/day_part";

export default {
  props: {
    value: String,
    forceAm: Boolean,
    forcePm: Boolean
  },
  data() {
    return {
      am: true,
      pm: true
    }
  },
  watch: {
    value() {
      if(!this.value) {
        this.$emit('input', dayParts.ALL_DAY);
      }
      else {
        this.computeData();
      }
    }
  },
  methods: {
    computeData() {
      this.am = this.value == dayParts.AM || this.value == dayParts.ALL_DAY;
      this.pm = this.value == dayParts.PM || this.value == dayParts.ALL_DAY;
    },
    emitInput() {
      let result = dayParts.ALL_DAY;
      if(!this.am) {
        result = dayParts.PM;
      }
      if(!this.pm) {
        result = dayParts.AM;
      }
      this.$emit('input', result);
    }
  },
  created() {
    if(!this.value) {
      this.$emit('input', dayParts.ALL_DAY);
    }
    else {
      this.computeData();
    }
  }
};
</script>
