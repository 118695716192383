<template>
  <span class="log-type" :class="log.type">
    {{text}}
  </span>
</template>

<script>
import {logTypesMapping} from "@/utils/log_types";

export default {
  props: {
    log: Object
  },
  computed: {
    text() {
      return logTypesMapping[this.log.type];
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/colors.scss";

.log-type {
  font-size: 0.7rem;
  color: white;
  font-weight: 600;
  width: 6rem;
  height: 1.2rem;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  @each $name, $hex in $log-colors {
    &.#{$name} {
      background: $hex;
    }
  }
}
</style>
