<template>
  <div class="box">
    <b-message v-if="error" type="is-danger">
      Une erreur est survenue.
    </b-message>
    <b-message v-if="success" type="is-success">
      Votre mot de passe a bien été mis à jour.
    </b-message>
    <validation-observer ref="form" #default="{handleSubmit}">
      <form @submit.prevent="handleSubmit(save)">
        <validation-provider name="old-password" rules="required" #default="{errors, failed}" slim>
          <b-field
            label="Ancien mot de passe"
            :type="{'is-danger': failed}"
            :message="errors[0]"
          >
            <b-input v-model="oldPassword" type="password" />
          </b-field>
        </validation-provider>
        <validation-provider name="new-password" rules="required|min:8|verify-password" #default="{errors, failed}" slim>
          <b-field
            label="Nouveau mot de passe"
            :type="{'is-danger': failed}"
            :message="errors[0]"
          >
            <b-input v-model="newPassword" type="password" />
          </b-field>
        </validation-provider>
        <validation-provider rules="required|password:@new-password" #default="{errors, failed}" slim>
          <b-field
            label="Nouveau mot de passe (confirmation)"
            :type="{'is-danger': failed}"
            :message="errors[0]"
          >
            <b-input v-model="confirmPassword" type="password" />
          </b-field>
        </validation-provider>
        <div class="buttons is-right">
          <button class="button is-primary">Sauvegarder</button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      success: false,
      error: false
    };
  },
  methods: {
    async save() {
      this.success = false;
      this.error = false;
      try {
        await axios.put("password", {old: this.oldPassword, new: this.newPassword});
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.success = true;
        this.$refs.form.reset();
      }
      catch(error) {
        if(error.response.status == 406) {
          this.$refs.form.setErrors({
            'old-password': ['Mot de passe incorrect.']
          });
        }
        else {
          this.error = true;
        }
      }
    }
  }
};
</script>

<style scoped>
.box {
  max-width: 600px;
  margin: auto;
}
</style>
