<template>
  <span v-if="singleDayEvent">
    {{strBeforeSingleDay}} <event-date :date-format="dateFormat" :event-date="event.start_date" />
  </span>
  <span v-else>
    {{strBefore}}
    <event-date :date-format="dateFormat" :event-date="event.start_date" />
    {{strBetween}}
    <event-date :date-format="dateFormat" :event-date="event.end_date" />
  </span>
</template>


<script>
import EventDate from './EventDate';

export default {
  props: {
    event: Object,
    dateFormat: {type: String, default: 'll'},
    strBeforeSingleDay: {type: String, default: ''},
    strBefore: {type: String, default: ''},
    strBetween: {type: String, default: '-'}
  },
  components: {EventDate},
  computed: {
    singleDayEvent() {
      return this.event.start_date.date == this.event.end_date.date;
    }
  }
};
</script>
