const logTypes = {
	CREATION: 'creation',
	UPDATE: 'update',
	DELETION: 'deletion',
};

export let logTypesMapping = {
	[logTypes.CREATION]: "Création",
	[logTypes.UPDATE]: "Modification",
	[logTypes.DELETION]: "Suppression",
};

export default logTypes;
