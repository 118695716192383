var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[(_vm.error)?_c('b-message',{attrs:{"type":"is-danger"}},[_vm._v(" Une erreur est survenue. ")]):_vm._e(),(_vm.success)?_c('b-message',{attrs:{"type":"is-success"}},[_vm._v(" Votre mot de passe a bien été mis à jour. ")]):_vm._e(),_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('validation-provider',{attrs:{"name":"old-password","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('b-field',{attrs:{"label":"Ancien mot de passe","type":{'is-danger': failed},"message":errors[0]}},[_c('b-input',{attrs:{"type":"password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"new-password","rules":"required|min:8|verify-password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('b-field',{attrs:{"label":"Nouveau mot de passe","type":{'is-danger': failed},"message":errors[0]}},[_c('b-input',{attrs:{"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|password:@new-password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('b-field',{attrs:{"label":"Nouveau mot de passe (confirmation)","type":{'is-danger': failed},"message":errors[0]}},[_c('b-input',{attrs:{"type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_c('div',{staticClass:"buttons is-right"},[_c('button',{staticClass:"button is-primary"},[_vm._v("Sauvegarder")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }