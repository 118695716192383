<template>
  <span v-if="!editMode">
    <user-name v-if="user" :user="user" />
    <template v-else>-</template>
    <b-button size="is-small" v-if="editable" icon-left="edit" @click="edit()" />
    <b-button v-if="splittable" size="is-small" icon-left="sitemap" @click="$emit('split')" />
  </span>
  <span v-else>
    <b-autocomplete
      rounded
      v-model="searchString"
      :data="filteredData"
      :custom-formatter="formatUser"
      placeholder="Entrer le prénom et/ou nom"
      @select="option => selectedUser = option"
    >
      <template slot="empty">Aucun utilisateur</template>
    </b-autocomplete>
    <b-button size="is-small" icon-left="times" @click="editMode = false" />
    <b-button size="is-small" class="is-primary" @click="save()" icon-left="check" />
  </span>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import UserName from "@/components/UserName";

import getErrorMessage, {errorCodes} from "@/utils/error_codes";
import {date2str} from "@/utils/date";

export default {
  props: {
    duty: Object,
    users: Object,
    targetDate: String, // if the update should be applied to a single day of the duty
    splittable: Boolean,
    displayStart: Object,
    displayEnd: Object
  },
  components: {UserName},
  data() {
    return {
      editMode: false,
      searchString: "",
      selectedUser: null
    };
  },
  computed: {
    editable() {
      return this.$store.getters.canManageDuties;
    },
    user() {
      return this.duty && this.duty.id_user ? this.users[this.duty.id_user] : null;
    },
    filteredData() {
      return Object.values(this.users).filter((option) => {
        return this.formatUser(option).toLowerCase().indexOf(this.searchString.toLowerCase()) >= 0;
      })
    }
  },
  methods: {
    formatUser(user) {
      return user.first_name + ' ' + user.last_name;
    },
    edit() {
      this.selectedUser = this.user;
      this.searchString = this.user ? this.formatUser(this.user) : '';
      this.editMode = true;
    },
    async save() {
      let id_user = this.selectedUser && this.searchString ? this.selectedUser.id : null;
      try {
        if(!this.splittable) {
          await axios.put('duties/update', null, {params: {
            duty_date: this.targetDate || this.duty.start_date.date,
            duty_type: this.duty.type,
            id_user: id_user
          }});
          this.$emit('saved-duties');
          this.editMode = false;
          return;
        }

        if(
          !this.displayStart.isSame(moment(this.duty.start_date.date)) ||
          !this.displayEnd.isSame(moment(this.duty.end_date.date))
        ) {
          let nbDays = this.displayEnd.diff(this.displayStart, 'days');
          for(let i = 0; i <= nbDays; i++) {
            await axios.put('duties/update', null, {params: {
              duty_date: date2str(moment(this.displayStart).add(i, 'days').toDate()),
              duty_type: this.duty.type,
              id_user: id_user
            }});
          }
          this.$emit('saved-duties');
          this.editMode = false;
          return;
        }

        // delete duty
        if(!id_user) {
          if(this.duty.id) {
            result = await axios.delete(`event/${this.duty.id}`);
            this.$emit('deleted-duty', this.duty);
          }
          this.editMode = false;
          return;
        }

        // update duty
        let data = {...this.duty, id_user};
        let result;
        if(this.duty.id) {
          result = await axios.put(`event/${this.duty.id}`, data);
        }
        else {
          result = await axios.post(`event`, data);
        }
        this.$emit('saved-duty', result.data);
        this.editMode = false;
      }
      catch(error) {
        let userName = this.selectedUser.first_name + ' ' + this.selectedUser.last_name[0] + '.'
        let errorMessage = getErrorMessage(error, {
          [errorCodes.OVERLAPPING_EVENT]: `${userName} n'est pas disponible à cette date.`
        });
        this.$buefy.toast.open({message: errorMessage, type: 'is-danger'});
        this.selectedUser = null;
        this.searchString = '';
      }
    }
  }
};
</script>


<style lang="scss" scoped>
/deep/ .button.is-small {
  font-size: 0.7em;
  width: 25px;
  height: 25px;
  margin-left: 0.5em;
}

/deep/ .autocomplete {
  max-width: 180px;
  display: inline-block;
  height: 25px;

  input {
    height: 30px;
    font-size: 0.9em;
    margin-top: -2px;
  }
}
</style>
