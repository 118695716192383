const groups = {
	INTERN: 'intern',
	DOCTOR: 'doctor',
	SURGEON: 'surgeon'
};

export let groupsMapping = {
	[groups.INTERN]: "Assistant",
	[groups.DOCTOR]: "Médecin",
	[groups.SURGEON]: "Chirurgien"
};

export default groups;
