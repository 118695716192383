<template>
  <span>
    {{eventDate.date | moment(dateFormat)}}
    <template v-if="am">(matin)</template>
    <template v-if="pm">(après-midi)</template>
  </span>
</template>

<script>
import dayParts from "@/utils/day_part";

export default {
  props: {
    dateFormat: String,
    eventDate: Object
  },
  computed: {
    am() {
      return this.eventDate.day_part == dayParts.AM;
    },
    pm() {
      return this.eventDate.day_part == dayParts.PM;
    }
  }
};
</script>
