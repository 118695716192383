import Vue from 'vue';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required, email, min} from 'vee-validate/dist/rules';

extend('required', {...required, message: 'Ce champ est obligatoire.'});
extend('email', {...email, message: 'Adresse e-mail non valide.'});
extend('min', {...min, message: 'Ce champ doit contenir au moins {length} caractères.'})
extend('password', {
  params: ['target'], validate(value, {target}) {
    return value === target;
  }, message: 'Les mots de passes ne sont pas identiques.'
});
extend('verify-password', {  // source: https://frankclark.xyz/veevalidate-strong-password-and-confirmation-validation
  message: 'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
  validate(value) {
      let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])");
      return strongRegex.test(value);
  }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
