import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    name: 'home',
    path: '/',
    component: require("@/pages/Home.vue").default
  },
  {
    name: 'duty-calendar',
    path: '/gardes',
    component: require("@/pages/DutyCalendar.vue").default
  },
  {
    name: 'user-summary',
    path: '/utilisateur/:idUser',
    component: require("@/pages/UserSummary.vue").default
  },
  {
    name: 'users',
    path: '/utilisateurs',
    component: require("@/pages/Users.vue").default
  },
  {
    name: 'report',
    path: '/rapport',
    component: require("@/pages/HolidayReport.vue").default
  },
  {
    name: 'history',
    path: '/historique',
    component: require("@/pages/History.vue").default
  },
  {
    name: 'password',
    path: '/mot-de-passe',
    component: require("@/pages/Password.vue").default
  },
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: 'is-active'
});

export default router;
