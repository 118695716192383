<template>
	<td
		:class="dayClasses"
		@mouseenter="mouseEnter()"
		@mouseleave="$emit('input', null)"
	>
		<v-popover v-if="event" :open.sync="openPopover">
			<span class="cell-text">{{cellText}}</span>

			<template #popover>
				<a v-close-popover class="close-popover">
					<b-icon icon="times" size="is-small" />
				</a>
				<div class="event-details">
					<div class="type" :class="eventProps.code">
						<strong>{{eventProps.code}}</strong> :  {{eventProps.desc}}
					</div>
					<div class="dates">
            <event-dates :event="event" date-format="l" str-before-single-day="le" str-before="du" str-between="au" />
					</div>

					<div class="buttons is-centered" v-if="canEdit">
						<button class="button is-small" v-close-popover @click="$emit('selectEvent', event)">
							<b-icon size="is-small" icon="edit" />
							<span>Modifier</span>
						</button>
						<button class="button is-small" v-close-popover @click="confirmDeletion()">
							<b-icon size="is-small" icon="trash" />
							<span>Supprimer</span>
						</button>
					</div>
				</div>
			</template>
		</v-popover>
	</td>
</template>

<script>
import {codesMapping, isDuty} from "@/utils/event_codes";
import dayPart from "@/utils/day_part";
import moment from "moment";

import EventDates from "./EventDates";

export default {
	model: {
		prop: 'highlightedEvent'
  },
	props: {
		user: Object,
		date: Object,
    highlightedEvent: Number,
    publicHoliday: Boolean
  },
  components: {
    EventDates
  },
	data() {
    return {
			today: new Date(),
			openPopover: false
		}
	},
	computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
		event() {
			for(let evt of this.user.events) {
				if(this.date >= moment(evt.start_date.date) && this.date <= moment(evt.end_date.date)) {
					return evt;
				}
			}
			return null;
		},
		partTimeOff() {
      let match = null;
			for(let partTimeOff of this.user.part_time_days_off) {
				if(this.date.day() === partTimeOff.day) {
          if(!match) {
            match = partTimeOff.day_part;
          }
          else {
            return dayPart.ALL_DAY; // two part time day off => all day day off
          }
				}
      }
			return match;
		},
		eventProps() {
			return this.event ? codesMapping[this.event.type] : {};
		},
		cellText() {
			if(this.event && (
        this.date.isSame(this.event.start_date.date, 'day') || this.date.date() == 1
      )) {
				return this.eventProps.code;
			}
			return "";
		},
		dayClasses() {
			let classes = "";
			if(this.date.isSame(moment(), 'day')) {
				classes += " current-day"
      }
			if(this.date.isoWeekday() == 6 || this.date.isoWeekday() == 7 || this.publicHoliday) {
				classes += " weekend"
			}
			if(this.event) {
        classes += " " + this.eventProps.code;
        if(this.date.isSame(this.event.start_date.date, 'day')) {
          classes += " event-" + this.event.start_date.day_part;
        }
        if(this.date.isSame(this.event.end_date.date, 'day')) {
          classes += " event-" + this.event.end_date.day_part;
        }
				if(this.highlightedEvent === this.event.id) {
					classes += " highlighted";
				}
			}
			if(this.partTimeOff) {
				classes += " part-time-off " + this.partTimeOff;
			}
			return classes;
    },
    canEdit() {
      return !isDuty(this.event) && (this.$store.getters.admin || (
        this.currentUser.id == this.event.id_user && moment(this.event.start_date.date) >= moment()
      ));
    }
	},
	methods: {
		mouseEnter() {
			if(this.event) {
				this.$emit('input', this.event.id)
			}
		},
		confirmDeletion() {
			this.$buefy.dialog.confirm({
				message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
				cancelText: 'Annuler',
				confirmText: 'Supprimer',
				type: 'is-danger',
				focusOn: 'cancel',
				onConfirm: () => this.$emit('deleteEvent', this.event.id)
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

@mixin morning-afternoon-part-time($color) {
  background: $color;

  &.am {
		background: linear-gradient(to right, $color 0%, $color 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0)  100%);
	}

	&.pm {
		background: linear-gradient(to left, $color 0%, $color 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0)  100%);
	}
}

@mixin morning-afternoon($color) {
  /deep/ .trigger {
    background: $color;
    cursor: pointer;
  }

  &.event-am, &.event-pm {
    /deep/ .trigger {
      width: 50%;
    }
	}

	&.event-pm {
    /deep/ .trigger {
      position: relative;
      left: 50%;
    }
	}
}

td {
	height: 1px; // fake height to allow to use height for inner popover div
	user-select: none;

  &.weekend {
    background-color: $weekend;
    @media print {
      background-color: #eee;
    }
  }

	&.part-time-off {
		background-color: $part-time-off-color;
    @include morning-afternoon-part-time($part-time-off-color);
    @media print {
      background-color: #ddd;
      @include morning-afternoon-part-time(#ddd);
    }
	}

  @each $name, $hex in $event-colors {
		&.#{$name} {
			$hl-color: lighten(saturate($hex, 15%), 3%);

			font-weight: bold;
			color: darken($hex, 20%);

			@include morning-afternoon($hex);

			&.highlighted {
				@include morning-afternoon($hl-color);
			}
		}
	}
}

.cell-text {
  margin: -1em;
}

/deep/ .v-popover {
	display: inline-block;
	width: 100%;
	height: 100%;
}

/deep/ .v-popover div {
	display: flex !important;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}


.event-details {
	min-width: 250px;
	padding: 0.5em;

	.buttons {
		margin-top: 0.8em;
	}

	.type {
		@each $name, $hex in $event-colors {
			&.#{$name} strong {
				color: darken($hex, 20%);
			}
		}
		margin-bottom: 0.25em;
	}

	.dates {
		font-size: 0.9em;
		color: #444;
	}
}
</style>
