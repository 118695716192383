<template>
  <div>
    <b-loading :active="loading" />

    <template v-if="users">
      <div class="month">
        <a class="prev" @click="previousMonth()">&#10094;</a>
        <div class="name">
          <h1>{{displayDate | moment("MMMM")}}</h1>
          <h3>{{displayDate | moment("YYYY")}}</h3>
        </div>
        <a class="next" @click="nextMonth()">&#10095;</a>
      </div>
      <table class="opthime-table is-fullwidth">
        <tr>
          <th>
            <v-popover>
              <button class="button is-small filters-button">
                <b-icon icon="filter" size="is-small" />
                <span>Filtres</span>
              </button>

              <template #popover>
                <a v-close-popover class="close-popover">
                  <b-icon icon="times" size="is-small" />
                </a>
                <div class="filters">
                  <h5>Spécialité</h5>
                  <b-field class="specialty-field">
                    <b-checkbox-button
                      v-for="specialty in availableSpecialties"
                      :key="specialty"
                      v-model="selectedSpecialties"
                      :native-value="specialty"
                      type="is-checked"
                      size="is-small"
                      class="specialty-control"
                    >
                      <b-icon icon="plus" size="is-small" class="icon-not-checked" />
                      <b-icon icon="check" size="is-small" class="icon-checked" />
                      <span>{{ specialtiesMapping[specialty] }}</span>
                    </b-checkbox-button>
                  </b-field>
                </div>
              </template>
            </v-popover>
          </th>
          <th v-for="day in days" :key="day.format()" :class="getDayClass(day)">
            <span class="day-name">{{ day | moment("dd")}}<br></span>
            {{ day | moment("DD") }}
          </th>
          <th v-for="i in maxNbDays - days.length" :key="i">
            <span class="hidden">00</span>
          </th>
        </tr>
        <tr v-for="user in filteredUsers" :key="user.id">
          <td>
            <user-name :user="user" />
          </td>
          <day-cell
            v-for="day in days" :key="`${user.id}-${day.format()}`"
            :user="user"
            :date="day"
            :public-holiday="isPublicHoliday(day)"
            v-model="highlightedEvent"
            @selectEvent="selectEvent"
            @deleteEvent="id => deleteEvent(id)"
          />
        </tr>
      </table>
      <div class="ophtime-table-footer">
        <v-popover placement="right">
          <button class="button legend is-small">
            <b-icon icon="info-circle" size="is-small" />
            <span>Légende</span>
          </button>

          <template #popover>
            <a v-close-popover class="close-popover">
              <b-icon icon="times" size="is-small" />
            </a>
            <div class="legend-item" v-for="(mapping, code) in eventCodesMapping" :key="code">
              <event-preview :eventType="code" />
            </div>
            <div class="legend-item event-preview">
              <span class="preview part-time"></span> Absence liée à temps partiel
            </div>
            <div class="legend-item event-preview">
              <span class="preview weekend"></span> Week-end / jour férié
            </div>
          </template>
        </v-popover>
        <button class="button is-primary" @click="newEvent()">
          <b-icon icon="plus" size="is-small" />
          <span>Ajouter un événement</span>
        </button>
      </div>
    </template>

    <b-modal :active.sync="activeModal" :width="600" trap-focus>
      <div class="box">
        <event-form :event="event" :users="users" @eventCreated="eventCreated" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import DayCell from "@/components/DayCell";
import EventForm from "@/components/EventForm";
import EventPreview from "@/components/EventPreview";
import UserName from "@/components/UserName";
import {codesMapping} from "@/utils/event_codes";
import {date2str} from "@/utils/date";
import specialties, {specialtiesMapping} from "@/utils/specialties";

export default {
  components: {
    DayCell,
    EventForm,
    EventPreview,
    UserName
  },
  data() {
    return {
      loading: true,
      displayDate: moment().startOf('month'),
      publicHolidays: [],
      maxNbDays: 31,
      users : null,
      highlightedEvent: null,
      activeModal: false,
      event: null,
      availableSpecialties: [...Object.values(specialties), null],
      selectedSpecialties: [],
    }
  },
  computed: {
    days() {
      let daysInMonth = moment(this.displayDate).daysInMonth();
      let days = [];
      for (let i = 1; i <= daysInMonth; i++) {
        days.push(moment(this.displayDate).date(i));
      }
      return days;
    },
    eventCodesMapping() {
      return codesMapping;
    },
    year() {
      return this.displayDate.year();
    },
    specialtiesMapping: () => specialtiesMapping,
    filteredUsers() {
      if(!this.selectedSpecialties.length) {
        return this.users;
      }
      return this.users.filter(u => this.selectedSpecialties.includes(u.specialty));
    }
  },
  watch: {
    year() {
      this.loadPublicHolidays();
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      let promisePublicHolidays = this.loadPublicHolidays();
      let res = await axios.get(`planning/${date2str(this.displayDate.toDate())}`);
      this.users = res.data;
      await promisePublicHolidays;
      this.loading = false;
    },
    async loadPublicHolidays() {
      let res = await axios.get(`public_holidays?year=${this.year}`);
      this.publicHolidays = res.data.map(date => moment(date));
    },
    isPublicHoliday(date) {
      return this.publicHolidays.some(hol => hol.isSame(date, 'day'));
    },
    previousMonth() {
      this.displayDate = moment(this.displayDate).add(-1, 'months');
      this.loadData();
    },
    nextMonth() {
      this.displayDate = moment(this.displayDate).add(1, 'months');
      this.loadData();
    },
    getDate(dayNumber) {
      return moment(this.displayDate).date(dayNumber);
    },
    getDayClass(date) {
      if(date.isSame(moment(), 'day')) {
        return "current-day";
      }
    },
    selectEvent(event) {
      if(event != null) {
        this.event = event;
        this.activeModal = true;
      }
    },
    newEvent() {
      this.event = null;
      this.activeModal = true;
    },
    eventCreated() {
      this.loadData();
    },
    async deleteEvent(idEvent) {
      await axios.delete(`event/${idEvent}`);
      this.loadData();
    }
  },
  created() {
    this.loadData();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.ophtime-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .button.legend .button.filters-button {
    background-color:#eee;
  }
}

table {
  height: 1px;

  tr {
    height: 100%;
  }

  th .day-name {
    font-size: 0.75em;
    padding: 0 -0.75em;
  }

  td, th {
    @media not print{
      &.current-day {
        border-left: 2px solid $primary;
        border-right: 2px solid $primary;
      }
    }

    &:not(:first-child) {
      font-size: 0.8em;
    }

    &:first-child {
      width: 1px;
      padding: 0.15em 1.5em;
      white-space: nowrap;
    }
  }
  @media not print{
    th.current-day {
      color: $dark-primary;
    }
  }
}

.legend-item {
  padding: 0.16em 0.8em 0.16em 0;
  display: flex;

  .preview {
    &.part-time {
      background: $part-time-off-color;
    }

    &.weekend {
      background: $weekend;
    }
  }
}

@media print {
  .ophtime-table-footer {
    display: none;
  }

  td {
    font-size: 12px;
  }

  .day-name {
    display: none;
  }
}

.filters {
  max-width: 370px;

  h5 {
    margin-bottom: 5px;
    font-weight: 500;
  }
}

.specialty-field {
  flex-wrap: wrap;
}

/deep/ .specialty-control {
  &:not(:last-child) {
    margin-right: 5px !important;
    margin-bottom: 5px !important;
  }

  .button .icon {
    margin-left: calc(-0.375em - 1px);
    margin-right: 0.1875em;
  }

  .is-checked {
    border-color: $primary;
    color: $primary;

    .icon-not-checked {
      display: none;
    }
  }

  :not(.is-checked) .icon-checked {
    display: none;
  }
}
</style>
