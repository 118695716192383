import moment from 'moment';

export let errorCodes = {
  OVERLAPPING_EVENT: 'overlapping_event',
  INSUFFICIENT_REMAINING_TRAINING: 'insufficient_remaining_trainings',
  INSUFFICIENT_REMAINING_HOLIDAYS: 'insufficient_remaining_holidays',
  INSUFFICIENT_REMAINING_SCIENTIFIC: 'insufficient_remaining_scientific',
  ALL_SPECIALISTS_UNAVAILABLE: 'all_specialists_unavailable',
  EXTRA_WORK_ON_NORMAL_WORKDAY: 'extra_work_on_normal_workday',
  UNKNOWN: 'unknown'
};

export function getErrorCode(error) {
  let detail = error.response.data.detail;
  return detail ? detail.error_code : errorCodes.UNKNOWN;
}

export default function getErrorMessage(error, customMessages={}) {
  let code = getErrorCode(error);
  if(customMessages[code] != null) {
    return customMessages[code];
  }

  let details = error.response.data.detail;
  switch(code) {
    case errorCodes.OVERLAPPING_EVENT:
      return "Les dates choisies chevauchent un événement existant.";
    case errorCodes.INSUFFICIENT_REMAINING_HOLIDAYS:
      return `
        Plus assez de jours de congés légaux disponibles pour l'année ${details.year} (${details.remaining} jours restants).
        Sélectionnez des congés sans solde ou réduisez la durée à ${details.remaining} jours pour l'année ${details.year}.
      `;
    case errorCodes.INSUFFICIENT_REMAINING_TRAINING:
      return `
        Plus assez de jours de congrès disponibles pour l'année ${details.year} (${details.remaining} jours restants).
        Sélectionnez des congés sans solde ou réduisez la durée à ${details.remaining} jours pour l'année ${details.year}.
      `;
    case errorCodes.INSUFFICIENT_REMAINING_SCIENTIFIC:
      return `
        Plus assez de congés scientifiques disponibles pour l'année ${details.year} (${details.remaining} jours restants).
        Sélectionnez des congés sans solde ou réduisez la durée à ${details.remaining} jours pour l'année ${details.year}.
      `;
    case errorCodes.ALL_SPECIALISTS_UNAVAILABLE: {
      let date = moment(details.date);
      return `
        Tous les autres médecins de la spécialité sont absents le ${date.format('l')}, êtes-vous sûr(e) de vouloir valider l'absence ?
      `;
    }
    case errorCodes.EXTRA_WORK_ON_NORMAL_WORKDAY:
      return `
        Les jours de travails supplémentaires doivent obligatoirement être définis le weekend ou lors de (demi-)jours habituellement non
        prestés dans le cadre d'un temps partiel.
      `;
    default:
      return "Une erreur inconnue a eu lieu."
  }
}
