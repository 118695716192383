<template>
  <div>
    <b-loading :active="loading" />
    <table class="opthime-table is-fullwidth">
      <template v-if="emptyPlanning && notPast">
        <tbody>
          <tr>
            <td>
              <p class="has-text-centered vertical-padding">
                <button class="button" @click="computeDuties()" v-if="canManageDuties">
                  <b-icon icon="magic" size="is-small" />
                  <span>Générer le planning de garde</span>
                </button>
                <span class="has-text-grey" v-else>
                  Les gardes ne sont pas encore planifiées pour ce mois.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-else>
        <thead>
          <tr>
            <th>Semaine</th>
            <th></th>
            <th>Lundi</th>
            <th>Mardi</th>
            <th>Mercredi</th>
            <th>Jeudi</th>
            <th>Vendredi</th>
            <th>Samedi</th>
            <th>Dimanche</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="week in weeks">
            <tr :key="week.start + '-G1'" class="separation" :class="{'active-week': week.active}">
              <th rowspan="2">{{week.start | moment("l")}} - {{week.end  | moment("l")}}</th>
              <td>G1</td>
              <duty-calendar-cell
                v-for="duty in week.duties[codes.G1]" :key="`${duty.start_date.date}-G1`"
                :duty="duty"
                :users="users"
                :target-week="week.start"
                @saved-duties="loadData"
                @saved-duty="saveDuty"
                @deleted-duty="deleteDuty"
              />
            </tr>
            <tr :key="week.start + '-G2'" :class="{'active-week': week.active}">
              <!-- missing td for rowspan week -->
              <td>G2</td>
              <duty-calendar-cell
                v-for="duty in week.duties[codes.G2]" :key="`${duty.start_date.date}-G2`"
                :duty="duty"
                :users="users"
                :target-week="week.start"
                @saved-duties="loadData"
                @saved-duty="saveDuty"
                @deleted-duty="deleteDuty"
              />
            </tr>
          </template>
        </tbody>
        <tfoot v-if="(!fullPlanning || !validatedPlanning) && canManageDuties">
          <tr v-if="notPast && !fullPlanning">
            <td colspan="9">
              <p class="has-text-right">
                <button class="button" @click="computeDuties()">
                  <b-icon icon="magic" size="is-small" />
                  <span>Générer le planning de garde</span>
                </button>
              </p>
            </td>
          </tr>
          <tr v-if="!validatedPlanning">
            <td colspan="9">
              <p class="info-validation">
                <b-icon icon="exclamation-triangle" size="is-small" /> <strong>Action requise</strong>
                Certaines gardes pour ce mois ne sont pas encore validées (les gardes non validées apparaissent avec un fond coloré).
                Celles-ci ne sont visibles que par les reponsables de garde. Pour les rendre officielles, veuillez vérifier leur
                pertinence et les valider.
              <p>
              <p class="info-validation">
                <button class="button is-primary" @click="validateDuties()">
                  <b-icon icon="check" size="is-small" />
                  <span>Valider le planning de garde</span>
                </button>
              </p>
            </td>
          </tr>
        </tfoot>
      </template>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import DutyCalendarCell from "./DutyCalendarCell";
import codes from "@/utils/event_codes";
import {date2str} from "@/utils/date";

export default {
  props: {
    month: moment,
    users: Object
  },
  components: {
    DutyCalendarCell
  },
  data() {
    return {
      duties: {},
      dutyPlanning: null,
      editedDutyPlanning: null,
      loading: false
    }
  },
  computed: {
    canManageDuties() {
      return this.$store.getters.canManageDuties;
    },
    emptyPlanning() {
      return Object.keys(this.duties).length == 0;
    },
    notPast() {
      return moment().isSame(this.month, 'month') || this.month >= moment();
    },
    fullPlanning() {
      return this.weeks.every(week => this.fullWeek(week));
    },
    validatedPlanning() {
      return Object.values(this.duties).every(duty => duty.validated);
    },
    weeks() {
      let monday = moment(this.month).startOf('month').startOf('isoweek');
      let weeks = [];
      let lastDuty = {[codes.G1]: null, [codes.G2]: null};
      while(monday.isSame(this.month, 'month') || monday < this.month) {
        let curWeek = {
          start: monday,
          end: moment(monday).endOf('isoweek'),
          active: moment().isSame(monday, 'week'),
          duties: {[codes.G1]: [], [codes.G2]: []}
        };
        for(let i = 0; i < 7; i++) { // days of the current week from monday to friday
          for(let type of [codes.G1, codes.G2]) {
            let day = moment(monday).add(i, 'days');
            let duties = curWeek.duties[type];
            let isSameAsLastDuty = lastDuty[type] && moment(lastDuty[type].end_date.date) >= day
            if(duties.length == 0 || !isSameAsLastDuty) {
              if(!isSameAsLastDuty) {
                lastDuty[type] = this.findDuty(type, day)
              }
              duties.push(lastDuty[type]);
            }
          }
        }
        weeks.push(curWeek);
        monday = moment(monday).add(1, 'week');
      }
      return weeks;
    },
    codes() {
      return codes;
    }
  },
  watch: {
    month() {
      this.loadData();
    }
  },
  methods: {
    dutyKey(duty) {
      return duty.type + '-' + date2str(
        moment.max(
          moment(duty.start_date.date),
          moment(this.month).startOf('month').startOf('isoWeek')
        ).toDate()
      );
    },
    findDuty(type, date) {
      let duty = this.duties[type + '-' + date.format('YYYY-MM-DD')]
      if(duty) {
        return duty;
      }
      else {  // create duty for target date without id/user
        let endDate = moment(date);
        let weekday = moment(date).isoWeekday()
        if((type == codes.G2 && weekday == 1) || (type == codes.G1 && weekday == 5)) {
          let remainderWeekEmpty = true;
          for(let i = 1; i <= 7 - moment(date).isoWeekday(); i++) {
            if(this.duties[type + '-' + moment(date).add(i, 'days').format('YYYY-MM-DD')]) {
              remainderWeekEmpty = false;
              break;
            }
          }
          if(remainderWeekEmpty) {
            endDate = endDate.endOf('isoWeek');
          }
        }
        return {
          type, start_date: {date: date2str(date.toDate())}, end_date: {date: date2str(endDate.toDate())}
        };
      }
    },
    async loadData() {
      this.loading = true;
      let {data} = await axios.get(`duties/${date2str(this.month.toDate())}`);
      this.processDuties(data);
      this.loading = false;
    },
    processDuties(data) {
      let mapped = data.map(duty => ({[this.dutyKey(duty)]: duty}));
      this.duties = Object.assign({}, ...mapped);
    },
    saveDuty(duty) {
      this.$set(this.duties, this.dutyKey(duty), duty);
    },
    deleteDuty(duty) {
      this.$delete(this.duties, this.dutyKey(duty));
    },
    async computeDuties() {
      this.loading = true;
      let {data} = await axios.post(`duties/${date2str(this.month.toDate())}`);
      this.processDuties(data);
      this.loading = false;
    },
    async validateDuties() {
      this.loading = true;
      let {data} = await axios.post(`validate_duties/${date2str(this.month.toDate())}`);
      this.processDuties(data);
      this.loading = false;
    },
    fullWeek(week) {
      return week.duties[codes.G1].every(duty => duty.id) && week.duties[codes.G2].every(duty => duty.id);
    }
  },
	created() {
    this.loadData();
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.opthime-table {
  border-bottom: 4px solid $primary;

  thead th {
    padding: 1em;
    font-weight: 500;
    border: 0px;
  }

  /deep/ td, th {
    padding: 0.5em;
    border-color: #c3c3c3;
  }

  /deep/ tr.separation:not(:first-of-type) {
    td, th {
      border-top: 3px solid #c3c3c3;
    }
  }

  tr.active-week {
    font-weight: 600;

    th {
      color: $primary;
      font-weight: 600;
    }
  }
}

.vertical-padding {
  padding-top: 2em;
  padding-bottom: 2em;
}

.info-validation {
  max-width: 1050px;
  margin: auto;
  margin-bottom: 10px;

  strong {
    text-transform: uppercase;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.8em;
  }
}

@media print {
  thead th {
    border: 2px solid #c3c3c3 !important;
  }

  tfoot {
    display: none;
  }

  .opthime-table {
    border: 0px;
  }
}
</style>
