<template>
  <div class="hero is-light is-fullheight" v-if="!$store.state.initialized">
    <b-loading :active="true" />
  </div>

  <div v-else-if="serverError">
    <main class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-7-tablet is-6-desktop is-4-widescreen">
              <div class="box">
                <h1 class="comm-error-header">Erreur de communication</h1>
                <p>Si cette erreur persiste, merci de contacter l'équipe technique d'Ophtime.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <div v-else-if="currentUser">
    <ophtime-navbar />
    <main>
      <div class="section">
        <div class="container">
          <template v-if="forbidden">
            <b-message type="is-danger">
              Vous n'avez pas le droit d'accéder à cette page.
            </b-message>
          </template>
          <router-view v-else />
        </div>
      </div>
    </main>
  </div>

  <login v-else :infoMessage="disconnected ? 'Votre session a expiré, veuillez vous reconnecter.' : null" />
</template>

<script>
import axios from 'axios';

import OphtimeNavbar from '@/components/layout/OphtimeNavbar.vue';
import Login from '@/pages/Login.vue';

export default {
  components: {OphtimeNavbar, Login},
  data() {
    return {
      disconnected: false,
      forbidden: false,
      serverError: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  watch:{
    '$route'() {
      this.forbidden = false;
    },
    currentUser() {
      if(this.currentUser) {
        this.disconnected = false;
      }
    }
  },
  async created() {
    await this.$store.dispatch('initializeStore');
    try {
        await axios.get('');
      }
      catch(error) {
        this.serverError = true;
      }
  },
  mounted() {
    window.intercepted.$on('response:401', () => {
      if(this.currentUser) {
        this.disconnected = true;
      }
      this.$store.dispatch('logout');
    });

    window.intercepted.$on('response:403', () => {
      this.forbidden = true;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.comm-error-header {
  background: $primary;
  color: $primary-invert;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 1.3em;
  padding: 1.75rem 2rem;
  margin: -1.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border-radius: 6px 6px 0 0;
}
</style>
