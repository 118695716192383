<template>
  <!-- Source: https://codepen.io/stevehalford/pen/YeYEOR -->
  <main class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7-tablet is-6-desktop is-4-widescreen">
            <form @submit.prevent="login()" class="box">
              <h1 class="login-header">Connexion</h1>
              <b-message v-if="invalidCredentials" type="is-danger">
                Informations de connexion incorrectes.
              </b-message>
              <b-message v-else-if="infoMessage" type="is-info">
                {{infoMessage}}
              </b-message>
              <b-field>
                <b-input placeholder="Email" icon="envelope" rounded v-model="email" />
              </b-field>
              <b-field>
                <b-input
                  placeholder="Mot de passe" type="password" icon="lock" rounded
                  v-model="password" password-reveal
                />
              </b-field>
              <button class="button is-primary is-fullwidth" :class="{'is-loading': loading}">
                Se connecter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    infoMessage: String
  },
  data() {
    return {
      email: "",
      password: "",
      invalidCredentials: false,
      loading: false
    }
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await this.$store.dispatch('login', {username: this.email, password: this.password});
      }
      catch(error) {
        this.invalidCredentials = true;
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.login-header {
  background: $primary;
  color: $primary-invert;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 1.4em;
  text-transform: uppercase;
  padding: 1.75rem 2rem;
  margin: -1.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
  border-radius: 6px 6px 0 0;
}

/deep/ .control.has-icons-left .icon.is-left {
  left: 5px;
  font-size: 0.8em;
  height: 2.25rem;
}

/deep/ .control.has-icons-right .icon.is-right {
  right: 5px;
  font-size: 0.8em;
  height: 2.25rem;
}

.button {
  margin-bottom: 0.5em;
}

/deep/ .message-body {
  padding: 0.5em 0.75em;
}
</style>
