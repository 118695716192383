<template>
  <td :class="cellClass" :colspan="nbDays" v-if="!split">
    <editable-duty
      :duty="duty"
      :users="users"
      :splittable="nbDays > 1"
      :display-start="displayStart"
      :display-end="displayEnd"
      :target-date="getTargetDate(0)"
      @saved-duties="$emit('saved-duties')"
      @saved-duty="duty => $emit('saved-duty', duty)"
      @deleted-duty="duty => $emit('deleted-duty', duty)"
      @split="split = true"
    />
  </td>
  <fragment v-else>
    <td v-for="i in nbDays" :key="i" :class="cellClass">
      <editable-duty
        :duty="duty"
        :users="users"
        :target-date="getTargetDate(i - 1)"
        @saved-duties="$emit('saved-duties')"
      />
    </td>
  </fragment>
</template>

<script>
import moment from 'moment';
import { Fragment } from 'vue-fragment';

import groups from "@/utils/groups";
import codes from "@/utils/event_codes";
import EditableDuty from './EditableDuty.vue';
import {date2str} from "@/utils/date";

export default {
  props: {
    duty: Object,
    users: Object,
    targetWeek: Object
  },
  components: {EditableDuty, Fragment},
  data() {
    return {
      split: false
    };
  },
  computed: {
    cellClass() {
      if(this.duty.id_user && !this.duty.validated) {
        if(this.users[this.duty.id_user].group == groups.INTERN || this.duty.type == codes.G2) {
          return 'pending-validation';
        }
        else {
          // special color to indicate potential problem if doctor selected for G1 duty
          return 'pending-validation-doctor';
        }
      }
      return '';
    },
    displayStart() {
      return moment.max(moment(this.duty.start_date.date), moment(this.targetWeek).startOf('isoWeek'));
    },
    displayEnd() {
      return moment.min(moment(this.duty.end_date.date), moment(this.targetWeek).endOf('isoWeek'));
    },
    nbDays() {
      return this.displayEnd.diff(this.displayStart, 'days') + 1;
    },
  },
  methods: {
    getTargetDate(offset) {
      return date2str(moment(this.displayStart).add(offset, 'days').toDate());
    }
  }
};
</script>


<style lang="scss" scoped>
td.pending-validation {
  background-color: rgb(255, 243, 220);
}

td.pending-validation-doctor {
  background-color: rgb(255, 226, 220);
}
</style>
