<!-- Simplified version of https://github.com/nono1526/vue-material-year-calendar -->
<template>
<div class="c-wrapper">
  <div class="calendar">
    <div class="calendar__title">{{ momentMonth | moment("MMMM") }}</div>
    <div class="calendar__body">
      <div v-for="day in 7" :key="`title${day}`" class="year-calendar-day day__weektitle">
        {{ moment.weekdaysMin(day) }}
      </div>
      <div v-for="(dayObj, key) in showDays" class="year-calendar-day" :key="`day${key}`">
        <div
          class="day"
          :class="{'other-month': dayObj.otherMonth, [dayObj.event.type]: dayObj.event}"
          v-tooltip.bottom="{content: getTooltip(dayObj.event), hideOnTargetClick: false}"
        >
          {{ dayObj.date | moment("D") }}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import {codesMapping} from "@/utils/event_codes";

export default {
  props: {
    events: {
      type: Array,
      default: () => []
    },
    month: {type: Number},
    year: Number
  },
  data () {
    return {
      showDays: [],
    }
  },
  computed: {
    moment() {
      return moment;
    },
    codesMapping() {
      return codesMapping;
    },
    momentMonth() {
      return moment().year(this.year).month(this.month).date(1);
    }
  },
  watch: {
    events() {
      this.initCalendar();
    }
  },
  methods: {
    initCalendar () {
      let eventsByDay = {}
      for(let event of this.events) {
        let momentDate = moment(event.date);
        eventsByDay[momentDate.date()] = event
      }

      let arrDays = [];
      let firstDay = moment(this.momentMonth).startOf("month").startOf("isoWeek");
      let lastDay = moment(this.momentMonth).endOf("month").endOf("isoWeek");

      let curDay = firstDay;
      while(curDay <= lastDay) {
        let otherMonth = !this.momentMonth.isSame(curDay, 'month');
        let event = {};
        if(!otherMonth && eventsByDay[curDay.date()]) {
          event = eventsByDay[curDay.date()];
        }
        arrDays.push({date: curDay, otherMonth, event});
        curDay = moment(curDay).add(1, 'days');
      }
      this.showDays = arrDays;
    },
    getTooltip(event) {
      if(!event.type) {
        return null;
      }
      let mapping = codesMapping[event.type];
      return mapping.desc;
    }
  },
  created () {
    this.initCalendar()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.c-wrapper {
  padding: 5px;
}

.calendar {
  background-color: #fff;
  min-height: 290px;
  text-align: center;
  color: rgba(#353C46, .8);
  border-radius: 2px;
  min-width: 0;
  position: relative;
  text-decoration: none;
  box-shadow: 0 0px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 6px;

  .calendar__title {
    font-weight: 500;
    flex: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(#C4C4C4, 0.4);
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
  }

  .calendar__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 0px 15px;
    min-width: 194px;
  }

  .year-calendar-day {
    flex: 14.28%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 31px;
    color: rgb(76, 150, 179);
  }

  .day__weektitle {
    color: rgba(#353C46, .8);
  }

  .day {
    font-size: 14px;
    user-select: none;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;

    &.other-month {
      color: #d2d2d2;
    }

    @each $name, $hex in $event-colors {
      &.#{$name} {
        cursor: pointer;
        background: $hex;
        color: white;
      }
    }
  }
}
</style>
