<template>
  <div>
    <b-loading :active="!users" />
    <template v-if="users">
      <div class="month">
        <a class="prev" @click="previousMonth()">&#10094;</a>
        <div class="name">
          <h1>{{currentMonth | moment("MMMM")}}</h1>
          <h3>{{currentMonth | moment("YYYY")}}</h3>
        </div>
        <a class="next" @click="nextMonth()">&#10095;</a>
      </div>
      <duty-calendar-month :month="currentMonth" :users="users" />
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import DutyCalendarMonth from "@/components/DutyCalendarMonth";

export default {
  components: {
    DutyCalendarMonth
  },
  data() {
    return {
      users: null,
      currentMonth: moment().startOf('month')
    }
  },
  methods: {
    previousMonth() {
      this.currentMonth = moment(this.currentMonth).add(-1, 'months');
    },
    nextMonth() {
      this.currentMonth = moment(this.currentMonth).add(1, 'months');
    },
  },
  async created() {
    let {data} = await axios.get('users');
    let mapped = data.map(item => ({[item.id]: item}) );
    this.users = Object.assign({}, ...mapped);  // mapping between user identifier and user object
  }
}
</script>


