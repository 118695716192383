<template>
	<validation-observer #default="{handleSubmit}">
		<form @submit.prevent="handleSubmit(save)">
      <b-message :active="!!backendError" type="is-danger">
        {{backendError}}
      </b-message>
      <template v-if="users && !event && isAdmin"> <!-- Admin user can create event for other users -->
        <validation-provider rules="required" #default="{errors, failed}" slim>
          <b-field
            label="Utilisateur"
            :type="{'is-danger': failed}"
            :message="errors[0]"
            horizontal
          >
            <b-autocomplete
              v-model="searchStringUser"
              :data="filteredUsers"
              :custom-formatter="formatUser"
              placeholder="Entrer le prénom et/ou nom"
              @select="option => internalEvent.id_user = option.id"
            >
              <template slot="empty">Aucun utilisateur</template>
            </b-autocomplete>
          </b-field>
        </validation-provider>
      </template>
			<validation-provider rules="required" #default="{errors, failed}" slim>
				<b-field
					label="Type"
					:type="{'is-danger': failed}"
					:message="errors[0]"
					horizontal
				>
					<b-select placeholder="Sélectionner le type" v-model="internalEvent.type">
						<option
							v-for="code in codes"
							:value="code.code"
							:key="code.code"
						>
							{{ code.desc }}
						</option>
					</b-select>
				</b-field>
			</validation-provider>
      <div class="columns">
        <div class="column">
          <validation-provider rules="required" #default="{errors, failed}" slim>
            <b-field
              label="Début"
              :type="{'is-danger': failed}"
              :message="errors[0]"
              horizontal
            >
              <b-datepicker
                placeholder="Sélectionner la date"
                v-model="internalEvent.start_date.date"
                :focused-date="internalEvent.end_date.date || new Date()"
                @input="setStartDate()"
              />
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-narrow">
          <day-part-field :force-pm="!singleDayEvent" v-model="internalEvent.start_date.day_part" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <validation-provider rules="required" #default="{errors, failed}" slim>
            <b-field
              label="Fin"
              :type="{'is-danger': failed}"
              :message="errors[0]"
              horizontal
            >
              <b-datepicker
                placeholder="Sélectionner la date"
                v-model="internalEvent.end_date.date"
                :focused-date="internalEvent.start_date.date || new Date()"
                @input="setEndDate()"
              />
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-narrow" v-if="!singleDayEvent">
          <day-part-field force-am v-model="internalEvent.end_date.day_part" />
        </div>
      </div>
			<div class="buttons is-right">
        <button class="button" type="button" @click="cancel()">Annuler</button>
				<button class="button is-primary">Sauvegarder</button>
			</div>
		</form>
	</validation-observer>
</template>

<script>
import axios from 'axios';

import {codesMapping, dutyCodes} from "@/utils/event_codes";
import {str2date, date2str} from "@/utils/date";
import dayParts from "@/utils/day_part";
import getErrorMessage, {getErrorCode, errorCodes} from "@/utils/error_codes";
import roles from "@/utils/roles";

import DayPartField from "./DayPartField";

export default {
	props: {
    event: Object,
    users: Array,
    allowedCodes: Array
  },
  components: {
    DayPartField
  },
	data() {
		return {
      internalEvent: {},
      backendError: null,
      searchStringUser: ''
		};
  },
	computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    isAdmin() {
      return this.$store.getters.admin;
    },
    codes() { // only codes that are not duties
      let allowedCodes = this.allowedCodes || Object.keys(codesMapping);
      return allowedCodes.reduce((acc, key) => (
        dutyCodes.includes(key) ? acc : {...acc, [key]: codesMapping[key]}
      ), {});
    },
    singleDayEvent() {
      let evt = this.internalEvent;
      return evt.end_date.date && evt.start_date.date && evt.start_date.date.getTime() == evt.end_date.date.getTime();
    },
    filteredUsers() {
      return this.users.filter((option) => {
        return this.formatUser(option).toLowerCase().indexOf(this.searchStringUser.toLowerCase()) >= 0;
      })
    }
	},
	methods: {
		async save(specialtyCheck = true) {
      this.backendError = null;
      let event = {
        ...this.internalEvent,
        start_date: this.eventDate2Str(this.internalEvent.start_date),
        end_date: this.eventDate2Str(this.internalEvent.end_date)
      };
      try {
        let options = {params: {check_specialty: specialtyCheck}};
        if(this.event) {
          let {data: persistedEvent} = await axios.put(`event/${this.event.id}`, event, options);
          Object.assign(this.event, persistedEvent);
        }
        else {
          let {data: persistedEvent} = await axios.post('event', event, options);
          this.$emit("eventCreated", persistedEvent);
        }
        this.$parent.close();
      }
      catch(error) {
        let code = getErrorCode(error);
        let message = getErrorMessage(error);
        if(code === errorCodes.ALL_SPECIALISTS_UNAVAILABLE) {
          this.$buefy.dialog.confirm({
            message,
            onConfirm: () => this.save(false)
          })
        }
        else {
          this.backendError = message;
        }
      }
    },
    setStartDate() {
      if(this.internalEvent.end_date.date && this.internalEvent.start_date.date > this.internalEvent.end_date.date) {
        this.internalEvent.end_date.date = null;
      }
      this.resetStartDatePart();
    },
    setEndDate() {
      if(this.internalEvent.start_date.date && this.internalEvent.start_date.date > this.internalEvent.end_date.date) {
        this.internalEvent.start_date.date = null;
      }
      this.resetStartDatePart();
    },
    resetStartDatePart() {
      if(!this.singleDayEvent && this.internalEvent.start_date.day_part == dayParts.AM) {
        this.internalEvent.start_date.day_part = dayParts.ALL_DAY;
      }
    },
    eventDate2Str(eventDate) {
      return {date: date2str(eventDate.date), day_part: eventDate.day_part};
    },
    eventDateStr2Date(eventDate) {
      return {date: str2date(eventDate.date), day_part: eventDate.day_part};
    },
    cancel() {
      this.$parent.close();
    },
    formatUser(user) {
      return user.first_name + ' ' + user.last_name;
    }
	},
	created() {
		if(this.event) {
      this.internalEvent = {
        start_date: this.eventDateStr2Date(this.event.start_date),
        end_date: this.eventDateStr2Date(this.event.end_date),
        type: this.event.type,
        id_user: this.event.id_user
      };
    }
    else {
      if([roles.SECRETARY, roles.DEVELOPER].includes(this.currentUser.role)) {
        // secretary cannot create events for himself/herself
        this.internalEvent = {start_date: {}, end_date: {}};
        this.searchStringUser = "";
      }
      else {
        this.internalEvent = {id_user: this.currentUser.id, start_date: {}, end_date: {}};
        this.searchStringUser = this.formatUser(this.currentUser);
      }
    }
	}
};
</script>


<style lang="scss" scoped>
/deep/ .field-label {
  width: 80px;
  flex-grow: initial;
  flex-basis: initial;
}

.columns {
  align-items: center;
  margin-bottom: 0 !important;
}
</style>
