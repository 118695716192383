import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import roles from "@/utils/roles";

Vue.use(Vuex);


function cleanAuthenticationState() {
  delete axios.defaults.headers.common['Authorization'];
  window.localStorage.removeItem('accessToken');
}

export default new Vuex.Store({
  state: {
    currentUser: null,
    initialized: false
  },
  mutations: {
    setInitialized(state) {
      state.initialized = true;
    },

    setCurrentUser(state, user) {
      state.currentUser = user;
    }
  },
  actions: {
    async initializeStore({state, commit, dispatch}) {
      if(state.initialized) {
        return;
      }

      let token = window.localStorage.accessToken;
      if(token == null) {
        commit('setInitialized');
        return;
      }

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await dispatch('fetchUser');
      commit('setInitialized');
    },

    async login({dispatch}, {username, password}) {
      let params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);
      params.append('grant_type', 'password');
      let {data} = await axios.post('login', params);
      let token = data.access_token;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      window.localStorage.accessToken = token;
      await dispatch('fetchUser');
    },

    async fetchUser({commit}) {
      let user = null;
      try {
        ({data: user} = await axios.get('user/current'));
      }
      catch(e) {
        cleanAuthenticationState();
        commit('setCurrentUser', null);
        return;
      }
      commit('setCurrentUser', user);
    },

    logout({commit}) {
      cleanAuthenticationState();
      commit('setCurrentUser', null);
    }
  },
  getters: {
    admin(state) {
      return state.currentUser && [roles.ADMIN, roles.SECRETARY, roles.DEVELOPER].includes(state.currentUser.role);
    },
    canManageDuties(state, getters) {
      return state.currentUser && (getters.admin || state.currentUser.role == roles.DUTY_MANAGER);
    },
    canViewReports(state, getters) {
      return state.currentUser && (getters.admin || state.currentUser.role == roles.POOL_MANAGER);
    }
  },
  modules: {
  }
})
