<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{name: 'home'}">
        OphTime
      </b-navbar-item>
    </template>

    <template #start>
			<b-navbar-item tag="router-link" :to="{name: 'duty-calendar'}">
				Gardes
			</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{name: 'user-summary', params: {idUser: currentUser.id}}" v-if="!secretaryOrDev">
				Mes infos
			</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{name: 'users'}" v-if="$store.getters.admin">
				Utilisateurs
			</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{name: 'report'}" v-if="$store.getters.canViewReports">
				Rapport
			</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{name: 'history'}" v-if="$store.getters.admin">
				Historique
			</b-navbar-item>
    </template>

    <template #end>
      <b-navbar-dropdown right hoverable>
        <template #label>
          {{currentUser.first_name}} {{currentUser.last_name[0]}}.
        </template>
        <b-navbar-item tag="router-link" :to="{name: 'password'}">
          Changer mon mot de passe
        </b-navbar-item>
        <b-navbar-item @click="$store.dispatch('logout')">
          Déconnexion
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import roles from "@/utils/roles";

export default {
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    secretaryOrDev() {
      return [roles.SECRETARY, roles.DEVELOPER].includes(this.currentUser.role);
    }
  }
};
</script>

<style lang="scss" scoped>
.user-picture {
  border-radius: 100px;
  margin-right: 0.5em;
}

/deep/ .navbar-brand {
  font-weight: bold;
}

.navbar {
  border-bottom: 2px solid #e7e7e7;
}
</style>
