const roles = {
	ADMIN: 'admin',
	POOL_MANAGER: 'pool_manager',
  DUTY_MANAGER: 'duty_manager',
  SECRETARY: 'secretary',
  DEVELOPER: 'developer'
};

export let rolesMapping = {
	[roles.ADMIN]: "Admin",
	[roles.POOL_MANAGER]: "Gestionnaire de pool",
  [roles.DUTY_MANAGER]: "Gestionnaire de gardes",
  [roles.SECRETARY]: "Secrétaire"
};

export default roles;
