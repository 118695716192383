<template>
  <div v-if="$store.getters.admin">
    <b-loading :active="!users" />
    <template v-if="users">
      <b-table :data="users" default-sort="last_name">
        <template #default="{row: user}">
          <b-table-column label="Nom" field="last_name" sortable searchable>
            {{ user.last_name }}
          </b-table-column>

          <b-table-column label="Prénom" field="first_name" sortable searchable>
            {{ user.first_name }}
          </b-table-column>

          <b-table-column label="Rôle" field="role" sortable>
            <template v-if="user.role">{{ rolesMapping[user.role] }}</template>
            <span v-else>-</span>
          </b-table-column>

          <b-table-column label="Groupe" field="groupName" sortable>
            {{user.groupName}}
            <span v-if="user.exempted">(E)</span>
          </b-table-column>

          <b-table-column label="Spécialité" field="specialtyName" searchable sortable>
            {{user.specialtyName}}
          </b-table-column>

          <b-table-column label="Horaire" centered>
            <span v-if="user.secretary">-</span>
            <template v-else-if="user.part_time_days_off.length">
              {{ 10 - user.part_time_days_off.length}} / 10
            </template>
            <span v-else>Temps plein</span>
          </b-table-column>

          <b-table-column label="Actions" centered>
            <div class="buttons is-right">
              <router-link
                v-if="!user.secretary"
                :to="{name: 'user-summary', params: {idUser: user.id}}"
                class="button is-small is-primary"
              >
                Détails
              </router-link>
              <button class="button is-small" @click="updateUser(user)">Editer</button>
              <button class="button is-small is-danger" @click="confirmDeletion(user)">Supprimer</button>
            </div>
          </b-table-column>
        </template>

        <template #searchable="props">
          <b-input
            v-if="!props.column.numeric"
            v-model="props.filters[props.column.field]"
            placeholder="Rechercher"
            icon="search"
            size="is-small"
          />
        </template>
      </b-table>

      <div class="buttons is-right add-user">
        <button class="button is-primary" @click="createUser()">Nouvel utilisateur</button>
      </div>

      <b-modal :active.sync="activeModal" :width="600" trap-focus>
        <div class="box">
          <user-form :user="modalUser" @userCreated="userCreatedHandler" />
        </div>
      </b-modal>
    </template>
  </div>
  <div v-else>
    <b-message type="is-danger">
      Vous n'avez pas le droit d'accéder à cette page.
    </b-message>
  </div>
</template>

<script>
import axios from 'axios';

import UserForm from "@/components/UserForm";
import {groupsMapping} from "@/utils/groups";
import {specialtiesMapping} from "@/utils/specialties";
import roles, {rolesMapping} from "@/utils/roles";

export default {
  components: {
    UserForm
  },
  data() {
    return {
      users: null,
      modalUser: null,
      activeModal: false
    };
  },
  computed: {
    rolesMapping: () => rolesMapping,
    groupsMapping: () => groupsMapping,
    specialtiesMapping: () => specialtiesMapping,
  },
  methods: {
    async loadUsers() {
      let res = await axios.get('users?include_secretaries=true');
      this.users = res.data.map(u => this.processUser(u));
    },
    processUser(user) {
      user.secretary = (user.role == roles.SECRETARY);
      user.specialtyName = user.specialty ? this.specialtiesMapping[user.specialty] : '-';
      user.groupName = user.secretary ? '-' : this.groupsMapping[user.group];
      return user;
    },
    nbHalfDayWork(user) {
      return 10 - user.partTimeDaysOff.length;
    },
    createUser() {
      this.modalUser = null;
      this.activeModal = true;
    },
    updateUser(user) {
      this.modalUser = user;
      this.activeModal = true;
    },
    userCreatedHandler(user) {
      this.processUser(user);
      this.users.push(user);
    },
    confirmDeletion(user) {
      this.$buefy.dialog.confirm({
        message: `Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.first_name} ${user.last_name} ?
          Toutes ses données seront supprimées. Cette action est irréversible.`,
				cancelText: 'Annuler',
				confirmText: 'Supprimer',
				type: 'is-danger',
				focusOn: 'cancel',
				onConfirm: () => this.delete(user)
			});
    },
    async delete(user) {
      await axios.delete(`user/${user.id}`);
      this.users = this.users.filter(u => u.id != user.id);
    }
  },
  created() {
    this.loadUsers();
  }
};
</script>

<style lang="scss" scoped>
.add-user {
  margin-top: 1em;
}

/deep/ th .icon {
  font-size: 0.8em !important;
}

/deep/ .control .icon {
  margin-left: 0px !important;
}
</style>
