<template>
  <div class="event-preview">
    <span class="preview" :class="typeData.code" :title="typeData.desc">
      {{typeData.code}}
    </span>
    <slot>
      {{typeData.desc}}
    </slot>
  </div>
</template>

<script>
import {codesMapping} from "@/utils/event_codes";

export default {
  props: {
    event: Object,
    eventType: String,
  },
  computed: {
    type() {
      return this.event ? this.event.type : this.eventType;
    },
    typeData() {
      return codesMapping[this.type];
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/colors.scss";

.event-preview {
  font-size: 0.9em;
  display: inline-flex;

  .preview {
    width: 3em;
    height: 1.5em;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    margin-right: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;

    @each $name, $hex in $event-colors {
      &.#{$name} {
        background: $hex;
        color: darken($hex, 25%);
      }
    }
  }
}
</style>
