import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/main.scss';

import Buefy from 'buefy';
import VTooltip from 'v-tooltip';

import VueMoment from 'vue-moment';
import moment from 'moment';
require('moment/locale/fr');

require('./vee-validate');

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDayNames: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  defaultMonthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  defaultFirstDayOfWeek: 1,
  defaultModalScroll: 'keep'
});
Vue.use(VTooltip);
Vue.use(VueMoment, {moment});

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

window.Vue = Vue;
window.axios = axios;
require('vue-axios-interceptors');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
