<template>
  <div v-if="$store.getters.admin">
    <b-loading :active="loading" />
    <template v-if="!loading">
      <b-table
        :data="logs"
        paginated
        backend-pagination
        @page-change="onPageChange"
        :total="logsCount"
        :per-page="perPage"
        pagination-size="is-small"
      >
        <template #default="{row: log}">
          <b-table-column label="Date">
            <span class="date">{{ log.date | moment('L H:mm:ss') }}</span>
          </b-table-column>
          <b-table-column label="Utilisateur">
            <user-name :user="users[log.id_user]" no-color v-if="users[log.id_user]" />
            <span v-else>Équipe technique</span>
          </b-table-column>
          <b-table-column label="Action">
            <log-type :log="log" />
          </b-table-column>
          <b-table-column label="Détails">
            <span class="details" v-html="log.message"></span>
          </b-table-column>
        </template>
      </b-table>
    </template>
  </div>
  <div v-else>
    <b-message type="is-danger">
      Vous n'avez pas le droit d'accéder à cette page.
    </b-message>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import UserName from "@/components/UserName";
import LogType from "@/components/LogType";
import logTypes from "@/utils/log_types";
import {codesMapping} from "@/utils/event_codes";
import dayPart from "@/utils/day_part";

export default {
  data() {
    return {
      users: null,
      logs: null,
      loading: true,
      page: 1,
      logsCount: null
    };
  },
  components: {
    UserName,
    LogType
  },
  computed: {
    perPage() {
      return 20;
    }
  },
  methods: {
    async loadUsers() {
      let res = await axios.get('users?include_secretaries=true&include_deleted=true');
      this.users = res.data.reduce(function(result, user) {
        result[user.id] = user;
        return result;
      }, {});
    },
    async loadLogsCount() {
      let res = await axios.get('logs_count', {params: {offset: (this.page - 1) * this.perPage, limit: this.perPage}});
      this.logsCount = res.data;
    },
    async loadLogs() {
      let res = await axios.get('logs', {params: {offset: (this.page - 1) * this.perPage, limit: this.perPage}});
      this.logs = res.data.map(u => this.processLog(u));
    },
    onPageChange(page) {
      this.page = page;
      this.loadLogs();
    },
    processLog(log) {
      let message;
      if(log.type == logTypes.CREATION) {
        message = `${this.formatEvent(log.event_after)} ajouté.`;
      }
      else if(log.type == logTypes.UPDATE) {
        let before = log.event_before;
        let after = log.event_after;
        message = `
          ${this.formatEvent(after, {
            boldType: before.type != after.type,
            boldUser: before.id_user != after.id_user,
            boldStart: !this.equalEventDates(before.start_date, after.start_date),
            boldEnd: !this.equalEventDates(before.end_date, after.end_date)
          })} modifié.<br>
          Précédemment : ${this.formatEvent(before)}.
        `;
      }
      else {
        message = `${this.formatEvent(log.event_before)} supprimé.`;
      }
      log.message = message;
      return log;
    },
    formatEvent(event, {boldType, boldUser, boldStart, boldEnd} = {}) {
      let eventDesc = this.formatStrong(codesMapping[event.type].desc, boldType);
      let user = this.users[event.id_user];
      let userName = this.formatStrong(user ? `${user.first_name} ${user.last_name}` : 'Inconnu', boldUser);
      let start = this.formatStrong(this.formatEventDate(event.start_date), boldStart);
      let end = this.formatStrong(this.formatEventDate(event.end_date), boldEnd);
      return `${eventDesc} pour ${userName} du ${start} au ${end}`;
    },
    formatEventDate(eventDate) {
      let dayPartMapping = {[dayPart.AM]: ' (AM)', [dayPart.PM]: ' (PM)', [dayPart.ALL_DAY]: ''}
      let date = moment(eventDate.date)
      return `${date.format('L')}${dayPartMapping[eventDate.day_part]}`;
    },
    formatStrong(text, isStrong) {
      return isStrong ? '<strong>' + text + '</strong>' : text;
    },
    equalEventDates(ed1, ed2) {
      return ed1.date == ed2.date && ed1.day_part == ed2.day_part;
    }
  },
  async created() {
    await Promise.all([this.loadUsers(), this.loadLogsCount()]);
    await this.loadLogs(); // remark: users must be loaded prior to logs processing
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

div {
  font-size: 0.95em;
}

.date {
  font-size: 0.85rem;
  color: #555;
}

/deep/ .details strong {
  font-weight: 600;
  color: map-get($log-colors, update);
}
</style>
