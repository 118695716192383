const specialties = {
	RETINA: 'retina',
	EYELID: 'eyelid',
	CORNEA: 'cornea',
	GLAUCOMA: 'glaucoma',
	STRABO: 'strabo',
};

export let specialtiesMapping = {
	[specialties.RETINA]: "Rétine",
	[specialties.EYELID]: "Paupière",
	[specialties.CORNEA]: "Cornée",
  [specialties.GLAUCOMA]: "Glaucome",
  [specialties.STRABO]: "Strabologie",
  [null]: "Aucune spécialité"
};

export default specialties;
