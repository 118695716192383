<template>
  <span :class="colorClass" v-if="user">{{user.last_name}} {{user.first_name[0]}}.</span>
  <span v-else>Inconnu</span>
</template>

<script>
import groups from "@/utils/groups";

export default {
  props: {
    user: Object,
    noColor: Boolean
  },
  computed: {
    colorClass() {
      if(this.noColor) {
        return;
      }
      return this.user.group == groups.INTERN ? 'intern' : 'doctor';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/colors.scss";

.intern {
  color: $dark-secondary;
}

.doctor {
  color: $dark-primary;
}
</style>
