<template>
	<validation-observer #default="{handleSubmit}">
		<form @submit.prevent="handleSubmit(save)">
			<validation-provider rules="required" #default="{errors, failed}" slim>
				<b-field
					label="Prénom"
					:type="{'is-danger': failed}"
					:message="errors[0]"
					horizontal
				>
					<b-input v-model="internalUser.first_name" />
				</b-field>
			</validation-provider>
      <validation-provider rules="required" #default="{errors, failed}" slim>
				<b-field
					label="Nom"
					:type="{'is-danger': failed}"
					:message="errors[0]"
					horizontal
				>
					<b-input v-model="internalUser.last_name" />
				</b-field>
			</validation-provider>
      <validation-provider rules="required|email" #default="{errors, failed, touched}" slim>
				<b-field
					label="E-mail"
					:type="{'is-danger': failed && touched}"
					:message="touched ? errors[0] : null"
					horizontal
				>
					<b-input v-model="internalUser.email" />
				</b-field>
			</validation-provider>
      <b-field label="Role" horizontal>
        <b-select v-model="internalUser.role">
          <option :value="null">
            Pas de rôle
          </option>
          <option v-for="(desc, role) in roles" :value="role" :key="role">
            {{ desc }}
          </option>
        </b-select>
      </b-field>
      <template v-if="!isSecretary">
        <validation-provider rules="required" #default="{errors, failed}" slim>
          <b-field
            label="Groupe"
            :type="{'is-danger': failed}"
            :message="errors[0]"
            horizontal
          >
            <b-select v-model="internalUser.group">
              <option v-for="(desc, group) in groups" :value="group" :key="group">
                {{ desc }}
              </option>
            </b-select>
          </b-field>
        </validation-provider>
        <b-field label="Exempté de gardes" horizontal v-if="isDoctor">
          <b-checkbox v-model="internalUser.exempted" />
        </b-field>
        <b-field label="Spécialité" horizontal>
          <b-select v-model="internalUser.specialty">
            <option :value="null">
              Pas de spécialité
            </option>
            <option v-for="(desc, spe) in specialties" :value="spe" :key="spe">
              {{ desc }}
            </option>
          </b-select>
        </b-field>
      </template>
      <validation-provider :rules="!user ? 'required' : ''" #default="{errors, failed}" slim>
        <b-field
          label="Mot de passe"
          :type="{'is-danger': failed}"
					:message="errors[0]"
          horizontal
        >
          <b-input
            v-model="internalUser.password"
            type="password"
            :placeholder="user ? 'Laisser vide pour conserver le mot de passe actuel.': ''"
            password-reveal
          />
        </b-field>
      </validation-provider>
      <b-field label="Horaire" class="vertical-center" horizontal v-if="!isSecretary">
        <table class="table">
          <tr>
            <th></th>
            <th v-for="day in weekDays" :key="day">{{day}}</th>
          </tr>
          <tr v-for="dayPart in [dayParts.AM, dayParts.PM]" :key="dayPart">
            <td>{{dayPart == dayParts.AM ? 'Matin' : 'Après-midi'}}</td>
            <td v-for="(day, idx) in weekDays" :key="day">
              <b-checkbox
                size="is-small"
                :value="isHalfDaySelected(idx + 1, dayPart)"
                @input="included => checkboxHandler(idx + 1, dayPart, included)"
              />
            </td>
          </tr>
        </table>
      </b-field>
			<div class="buttons is-right">
				<button class="button" type="button" @click="cancel()">Annuler</button>
        <button class="button is-primary">Sauvegarder</button>
			</div>
		</form>
	</validation-observer>
</template>

<script>
import axios from 'axios';

import groups, {groupsMapping} from "@/utils/groups";
import {specialtiesMapping} from "@/utils/specialties";
import roles, {rolesMapping} from "@/utils/roles";
import moment from 'moment';
import dayParts from "@/utils/day_part";

export default {
	props: {
		user: Object
	},
	data() {
		return {
			internalUser: {}
		};
	},
	computed: {
    groups: () => groupsMapping,
    specialties: () => specialtiesMapping,
    roles: () => rolesMapping,
    dayParts: () => dayParts,
    weekDays() {
      let days = moment.weekdaysMin(true);
      return days.slice(0, 5);
    },
    isSecretary() {
      return this.internalUser.role == roles.SECRETARY;
    },
    isDoctor() {
      return [groups.DOCTOR, groups.SURGEON].includes(this.internalUser.group);
    }
	},
	methods: {
    isHalfDaySelected(day, day_part) {
      return !this.internalUser.part_time_days_off.some(v => (v.day == day && v.day_part == day_part));
    },
    checkboxHandler(day, day_part, included) {
      if(!included) {  // checkbox deselected => add half-day to days off
        this.internalUser.part_time_days_off.push({day, day_part});
      }
      else {
        this.internalUser.part_time_days_off = this.internalUser.part_time_days_off.filter(
          o => o.day !== day || o.day_part !== day_part
        );
      }
    },
		async save() {
      if(this.isSecretary) {
        this.internalUser.group = groups.INTERN;  // assign group to pass validation (not used for secretaries)
        this.internalUser.specialty = null;
        this.internalUser.part_time_days_off = [];
      }
      if(!this.isDoctor) {
        this.internalUser.exempted = false;
      }
      if(this.user) {
        let {data: user} = await axios.put(`user/${this.user.id}`, this.internalUser);
        Object.assign(this.user, user);
      }
      else {
        let {data: user} = await axios.post('user', this.internalUser);
        this.$emit('userCreated', user);
      }
			this.$parent.close();
    },
    cancel() {
      this.$parent.close();
    }
	},
	created() {
		if(this.user) {
      this.internalUser = {...this.user};
      this.internalUser.part_time_days_off = [...this.user.part_time_days_off];
    }
    else {
      this.internalUser = {part_time_days_off: []};
    }
	}
};
</script>

<style lang="scss" scoped>
.vertical-center /deep/ .field-label {
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
</style>
